import React, { useState, useEffect } from 'react';
import axios from "axios";

import { useMediaQuery, Container, Typography, Grid, CircularProgress, Box, Button, Pagination, Link} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import MultiSelect from '../search/MultiSelect';

import Services from './Services';

const containerStyle = {
    marginBottom: (theme) => theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}
  
const headerContainerStyle = {
    height: {xs: '240px', sm: '360px'},
    position: 'relative',
    marginBottom: (theme) => theme.spacing(4),
};
  
const headerImageStyle = {
    width: '100vw',
    height: '100%',
    objectFit: 'cover',
}

const headerTextStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#ffffff',
    fontSize: {xs: '32px', sm: '72px'},
    lineHeight: '200%'
};

const headerSecondTextStyle = {
    fontSize: {xs: '18px', sm: '32px'},
  };

const descriptionTextStyle = {
  fontSize: {xs: '18px', sm: '28px'},
    marginBottom: {xs: '20px', sm: '40px'},
    textAlign: 'center'
}
  
const searchTextStyle = {
  fontWeight: 'bold',
  marginBottom: {xs: '20px', sm: '40px'},
}

const searchContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: (theme) => theme.spacing(6),
  borderStyle: 'solid',
  borderWidth: '5px',
  borderColor: (theme) => theme.palette.primary.light,
  borderRadius: '25px',
  marginBottom: (theme) => theme.spacing(2),
}

const searchBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const searchGridStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}

const buttonStyle = {
  margin: (theme) => theme.spacing(4, 4),
  fontSize: { xs: '12px', sm: '16px' },
  width: {xs: '60%', sm: "40%", md: "30%"},
}

const circularProgressStyle = {
  margin: (theme) => theme.spacing(4)
}

const paginationStyle = {
  marginBottom: (theme) => theme.spacing(4)
}

const footerImageContainerStyle = {
  width: {xs: '100%', sm: '80%'},
};

const footerImageStyle = {
  width: '100%',
  height: {xs: '100%', sm: '80%'},
  objectFit: 'contain'
}


export default function ServiceSearch() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [kuntaOptions, setKuntaOptions] = useState([]);
  const [maakuntaOptions, setMaakuntaOptions] = useState([]);
  const [palveluOptions, setPalveluOptions] = useState([]);
  const [yrityksetOptions, setYrityksetOptions] = useState([]);
  const [loading, setLoading] = useState(true)

  const [maakunnat, setMaakunnat] = useState([])
  const [kunnat, setKunnat] = useState([])
  const [yritykset, setYritykset] = useState([])
  const [palvelut, setPalvelut] = useState([])

  const [palvelumaara, setPalvelumaara] = useState(0)
  const [palvelumaaraHaetut, setPalvelumaaraHaetut] = useState(0)
  const [page, setPage] = useState(1)

  const [palvelulista, setPalvelulista] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);

    getKunnat();
    getMaakunnat();
    getPalvelut();
    getYritykset();

    getPalvelulista();
  }, [])

  useEffect(() => {
    getPalvelulista();
  }, [page])

  useEffect(() => {
    getPalvelumaara();
  }, [maakunnat, kunnat, palvelut, yritykset])

  const getKunnat = async () => {
    try {
      const response = await axios.get("/api/data/kunnat")
      setKuntaOptions(response.data)
    } catch(error) {
      console.log(error)
    }
  }

  const getMaakunnat = async () => {
    try {
      const response = await axios.get("/api/data/maakunnat")
      setMaakuntaOptions(response.data)
    } catch(error) {
      console.log(error)
    }
  }

  const getPalvelut = async () => {
    try {
      const response = await axios.get("/api/data/palvelut-rajattu")
      setPalveluOptions(response.data)
    } catch(error) {
      console.log(error)
    }
  }

  const getYritykset = async () => {
    try {
      const response = await axios.get("/api/data/yritysnimet")
      setYrityksetOptions(response.data)
    } catch(error) {
      console.log(error)
    }
  }

  const getPalvelulista = async () => {
    setLoading(true)
    const filters = {
      maakunnat,
      kunnat,
      palvelut,
      yritykset,
      bottom_limit: (page - 1) * 10
    }
    try {
      const response = await axios.post("/api/yritys/hae-yrityslista", filters)

      setPalvelulista(response.data)

      const palvelut_lkm = await getPalvelumaara()
      setPalvelumaaraHaetut(palvelut_lkm)

      setLoading(false)
    } catch(error) {
      console.log(error);
      setLoading(false)
    }
  }


  const getPalvelumaara = async () => {
    const filters = {
      maakunnat,
      kunnat,
      palvelut,
      yritykset
    }

    try {
      const response = await axios.post("/api/yritys/hae-maara", filters)
      setPalvelumaara(response.data.palvelumaara)
      return response.data.palvelumaara
    } catch(error) {
      console.log(error);
    }
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleFetchPalvelutClick = () => {
    setLoading(true)
    setPage(1);
    getPalvelulista()
  };


  return (
    <Container maxWidth="lg" sx={containerStyle}>
        <Box component="div" sx={headerContainerStyle}>
            <img src={'../header-image-nettimetsa.png'} alt={""} style={headerImageStyle} />
            <Typography variant='h1' sx={headerTextStyle}><b>Nettimetsä.fi</b><br/><Typography sx={headerSecondTextStyle}><b>Julkinen metsätilojen ja -palveluiden markkinapaikka</b></Typography></Typography>
        </Box>
        <Typography variant="h5" component="h1" sx={descriptionTextStyle}>Etsi palvelua-sivulta metsänomistajat löytävät parhaat paikallisesti toimivat palveluntarjoajat</Typography>
        <Container sx={searchContainerStyle}>
            <Typography variant="h4" component="h1" sx={searchTextStyle}>Etsi palvelua</Typography>
            <Grid item container spacing={2} xs={12} sx={searchGridStyle}>
                <Grid item xs={12} sm={12} md={6} lg={3} sx={searchBoxStyle}>
                    <MultiSelect name="Maakunta" items={maakuntaOptions} selectedItems={maakunnat} setSelectedItems={setMaakunnat} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} sx={searchBoxStyle}>
                    <MultiSelect name="Kunta" items={kuntaOptions} selectedItems={kunnat} setSelectedItems={setKunnat} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} sx={searchBoxStyle}>
                    <MultiSelect name="Yritys" items={yrityksetOptions} selectedItems={yritykset} setSelectedItems={setYritykset} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} sx={searchBoxStyle}>
                    <MultiSelect name="Palvelu" items={palveluOptions} selectedItems={palvelut} setSelectedItems={setPalvelut} />
                </Grid>
                <Button variant="contained" sx={buttonStyle} onClick={handleFetchPalvelutClick} >
                  Hae {palvelumaara} Palvelua
                </Button>
            </Grid>
        </Container>
        {loading ?
            <CircularProgress color="primary" sx={circularProgressStyle}/>
        :
            <Services palvelulista={palvelulista}/>
        }
        <Pagination size={isMobile ? "small" : "large"} count={Math.ceil(palvelumaaraHaetut / 10)} page={page} onChange={handlePageChange} sx={paginationStyle}/>
        <Box component="div" sx={footerImageContainerStyle}>
          <img src={'../nettimetsa-yhteistyokumppanit.jpg'} alt={""} style={footerImageStyle} />
        </Box>
    </Container>
  );
}