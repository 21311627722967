import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import RegisterCompany from './RegisterCompany';

import { Backdrop, CircularProgress, } from '@mui/material';

export default function CompanyWrapper() {
  const navigate = useNavigate();
  const { id } = useParams()

  const [loading, setLoading] = useState(true);
  const [companyInfo, setCompanyInfo] = useState({
    id: "",
    ytunnus: "",
    nimi: "",
    email: "",
    phoneNum: "",
    osoite: "",
    palvelunimi: "",
    maakunnat: [],
    kunnat: [],
    palvelu: "",
    kuvaus: "",
    prioriteetti: "",
    logo: null
  })

  const getYritys = async (id) => {
    try {
      const response = await axios.get("/api/admin/yritys/" + id);

      const kunnatArray = response.data.kunnat.split(',');
      response.data.kunnat = kunnatArray;

      const maakunnatArray = response.data.maakunnat.split(',');
      response.data.maakunnat = maakunnatArray;

      let logo = null;

      if (response.data.logoUrl) {
        logo = { 'kuva_url': response.data.logoUrl }
      } 

      delete response.data.logoUrl

      console.log(response.data)

      setCompanyInfo({...response.data, logo});
      setLoading(false)
    } catch(error) {
      console.log(error)
      navigate('/haku');
    }
  }

  const verifyAdmin = async () => {
    try {
      await axios.get("/api/admin/verifioi")
      setLoading(false);
    } catch(error) {
      navigate('/haku');
    }
  }


  useEffect(() => {
    if (id === 'uusi') {
      verifyAdmin();
    } else {
      getYritys(id)
    }
  }, [])


  return (
    <div>
      {loading?
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      :
        <RegisterCompany id={id} companyInfo={companyInfo} getYritys={getYritys} />
      }
    </div>
  );
}